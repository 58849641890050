import { Dispatch } from "redux"
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api"
import { GetState } from "../reducers"

// Branch Action Enum
export enum BranchActionList {
    ADD_BRANCH = 'ADD_BRANCH',
    FETCH_BRANCH = 'FETCH_BRANCH',
    UPDATE_BRANCH = 'UPDATE_BRANCH',
    DELETE_BRANCH = 'DELETE_BRANCH',
}

// Branch Type Interface
export interface BranchType {
    id: number;
    name: string;
    affiliatedUniversity: string;
    affiliationNo: string;
    recognisedBy: string;
    sponsoredBy: string;
    approvedBy: string;
    place: string;
    photoUrl: string;
}

// Branch Actions Interfaces
export interface AddBranchAction {
    type: BranchActionList.ADD_BRANCH;
    data: BranchType;
}

export interface FetchBranchAction {
    type: BranchActionList.FETCH_BRANCH;
    data: Array<BranchType>;
}

export interface UpdateBranchAction {
    type: BranchActionList.UPDATE_BRANCH;
    data: BranchType;
}

export interface DeleteBranchAction {
    type: BranchActionList.DELETE_BRANCH;
    data: number;
}

export type BranchActions = AddBranchAction | FetchBranchAction | UpdateBranchAction | DeleteBranchAction;

// Redux Action Creators for Branch

// Add Branch
export const addBranch = (data: FormData) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.post<APIResponse<BranchType>>('branch/', data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<AddBranchAction>({
                    type: BranchActionList.ADD_BRANCH,
                    data: response.data.data
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Branch Added');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to add'
                : 'Unable to add');
        });
    };
}

// Update Branch
export const updateBranch = (data: FormData, id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.post<APIResponse<BranchType>>(`branch/?id=${id}`, data, config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<UpdateBranchAction>({
                    type: BranchActionList.UPDATE_BRANCH,
                    data: response.data.data
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Branch Updated');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to update'
                : 'Unable to update');
        });
    };
}

// Fetch Branch
export const fetchBranch = () => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.get<APIResponse<BranchType[]>>('branch/', config).then(response => {
            if (response.status === 200 && response.data.data) {
                dispatch<FetchBranchAction>({
                    type: BranchActionList.FETCH_BRANCH,
                    data: response.data.data
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Fetch Successful');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to fetch'
                : 'Unable to fetch');
        });
    };
}

// Delete Branch
export const deleteBranch = (id: number) => {
    return async (dispatch: Dispatch, getState: GetState) => {

        const token = getState().authUser.token!;
        const config = getAxiosRequestConfig(token);

        return api.delete<APIResponse<BranchType>>(`branch/?id=${id}`, config).then(response => {
            if (response.status === 200) {
                dispatch<DeleteBranchAction>({
                    type: BranchActionList.DELETE_BRANCH,
                    data: id
                });
                return Promise.resolve(response.data.message
                    ? response.data.message
                    : 'Branch Deleted');
            } else {
                throw { response };
            }
        }).catch(error => {
            return Promise.reject(error.response
                ? error.response.data.message
                    ? error.response.data.message
                    : 'Unable to delete'
                : 'Unable to delete');
        });
    };
}
