import { ChangeEventHandler, MouseEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList, isValidEmail } from "../config/functions";
import { UserStatus, UserStatusList, UserType, addUser, fetchUser, updateUser } from "../redux/actions/userActions";
import { AppDispatch } from "../redux/store";
import Option from "./Option";
import FormInput from "./custom/FormInput";
import { ToastTypes, useToast } from "./toast/ToastProvider";
import { StoreState } from "../redux/reducers";
import { BranchType } from "../pages/Interface";
import { fetchBranch } from "../redux/actions/branchActions";
import Select from 'react-select';
import { DropDownType, fetchDropDown } from "../redux/actions/dropDownAction";

interface Props {
    formType: FormType;
    editData?: UserType;
    onSave: () => void;
}

const STATUS = [
    { text: 'Active', value: 'Active' },
    { text: 'Inactive', value: 'Inactive' }
];

const UserForm = ({ formType, onSave, editData }: Props) => {
    const toast = useToast();
    const dispatch = useDispatch<AppDispatch>();
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)

    const [userId, setUserId] = useState<string>('');
    const [userIdError, setUserIdError] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [designation, setDesignation] = useState<string>('');
    const [designationError, setDesignationError] = useState<string>('');
    const [branchId, setBranchId] = useState<string>('');
    const [branchIdError, setBranchIdError] = useState<string>('');
    const [role, setRole] = useState<string>('');
    const [roleError, setRoleError] = useState<string>('');
    const [status, setStatus] = useState<UserStatus>(UserStatusList.ACTIVE);
    const [statusError, setStatusError] = useState<string>('');
    const [emailId, setEmailId] = useState<string>('');
    const [emailIdError, setEmailIdError] = useState<string>('');
    const [mobile, setMobile] = useState<string>('');
    const [mobileError, setMobileError] = useState<string>('');

    useEffect(() => {
        dispatch(fetchBranch());
        dispatch(fetchDropDown())
    }, []);

    const resetStates = () => {
        setUserId('');
        setUserIdError('');
        setPassword('');
        setPasswordError('');
        setName('');
        setNameError('');
        setEmailId('');
        setEmailIdError('');
        setMobile('');
        setMobileError('');
        setDesignation('');
        setDesignationError('');
        setRole('');
        setRoleError('');
        setBranchId('');
        setBranchIdError('');
        setStatus(UserStatusList.ACTIVE);
        setStatusError('');
    }

    const handleChange = (setter: React.Dispatch<React.SetStateAction<any>>) => (value: any) => {
        setter(value);
    }

    const handleStatus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setStatus(e.target.value as UserStatus)
        setStatusError('')
    }

    const handleClear: MouseEventHandler<HTMLButtonElement> = () => {
        resetStates();
    }

    const handleSave = () => {
        let error = false;
        const data: UserType = {
            userId,
            password,
            name,
            emailId,
            mobileNumber: mobile,
            designation,
            branchId,
            role: role.toUpperCase() ?? '',
            status
        };

        if (formType === FormTypeList.ADD) {
            if (!data.userId) {
                setUserIdError('UserId required');
                error = true;
            } else if (data.userId.length > 15) {
                setUserIdError('UserId must be within 15 characters');
                error = true;
            }

            if (!data.password) {
                setPasswordError('Password required');
                error = true;
            } else if (data.password.length < 6 || data.password.length > 10) {
                setPasswordError('Password must be 6 to 10 characters long');
                error = true;
            }
        }

        if (!data.name) {
            setNameError('Name required');
            error = true;
        } else if (data.name.length > 30) {
            setNameError('Name must be within 30 characters');
            error = true;
        }

        if (!data.designation) {
            setDesignationError('Designation required');
            error = true;
        } else if (data.designation.length > 30) {
            setDesignationError('Designation must be within 30 characters');
            error = true;
        }

        if (!data.branchId) {
            setBranchIdError('Branch required');
            error = true;
        }

        if (!data.role) {
            setRoleError('Role required');
            error = true;
        } else if (data.role.length > 15) {
            setRoleError('Role must be within 15 characters');
            error = true;
        }

        if (data.emailId && !isValidEmail(data.emailId)) {
            setEmailIdError('Invalid Email ID');
            error = true;
        }

        if (![UserStatusList.INACTIVE, UserStatusList.ACTIVE].includes(data.status)) {
            setStatusError('Status must be ACTIVE/INACTIVE');
            error = true;
        }

        if (!error) {
            if (formType === FormTypeList.ADD) {
                dispatch(addUser(data)).then(text => {
                    toast(text);
                    onSave();
                    dispatch(fetchUser())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR);
                });
            } else if (formType === FormTypeList.UPDATE && editData?.userId) {
                dispatch(updateUser(data, editData.userId)).then(text => {
                    toast(text);
                    onSave();
                    dispatch(fetchUser())
                }).catch(text => {
                    toast(text, ToastTypes.ERROR);
                });
            }
        }
    }

    useEffect(() => {
        if (formType === FormTypeList.UPDATE && editData?.userId) {
            setName(editData.name);
            setEmailId(editData.emailId ?? '');
            setMobile(editData.mobileNumber ?? '');
            setDesignation(editData.designation);
            setBranchId(editData.branchId);
            setRole(editData.role);
            setStatus(editData.status);
        } else {
            resetStates();
        }
    }, [formType, editData]);

    return (
        <div className='container'>
            <div className="row">
                {formType === FormTypeList.ADD && <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='UserId'
                        name='UserId'
                        labelClassName="required"
                        value={userId}
                        onChange={e => setUserId(e.target.value)}
                        placeholder='UserId'
                        errorText={userIdError}
                        containerClass="mb-2"
                    />
                </div>}

                {formType === FormTypeList.ADD && <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Password'
                        name='Password'
                        labelClassName="required"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        placeholder='Password'
                        errorText={passwordError}
                        containerClass="mb-2"
                    />
                </div>}
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Name'
                        name='Name'
                        labelClassName="required"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='Name'
                        errorText={nameError}
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Email ID'
                        name='Email'
                        value={emailId}
                        onChange={e => setEmailId(e.target.value)}
                        placeholder='Email ID'
                        errorText={emailIdError}
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Mobile Number'
                        name='Mobile'
                        value={mobile}
                        onChange={e => setMobile(e.target.value)}
                        placeholder='Mobile Number'
                        errorText={mobileError}
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Designation'
                        name='Designation'
                        labelClassName="required"
                        value={designation}
                        onChange={e => setDesignation(e.target.value)}
                        placeholder='Designation'
                        errorText={designationError}
                        containerClass="mb-2"
                    />
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Branch'
                        name='Branch'
                        labelClassName="required"
                        type='select'
                        value={branchId}
                        onChange={(e) => setBranchId(e.target.value)}
                        errorText={branchIdError}
                    >
                        <Option value=''>Select</Option>
                        {branchList.map(branch => {
                            return <Option value={branch.id}>{branch.name}</Option>
                        })}
                    </FormInput>
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <label className="mt-1 mb-1">Role <span className="text-danger">*</span> : </label>
                    <select
                        className="form-control"
                        value={role}
                        onChange={(e) => {
                            setRole(e.target.value);
                            setRoleError('');
                        }}

                    >
                        <option value="" disabled>Select Role</option>
                        {
                            dropDownList.filter(dl => dl.category === 'Role').map((dd) => {
                                return <option value={dd.title}>{dd.title}</option>
                            })
                        }
                    </select>
                    {roleError && <div className="text-danger">{roleError}</div>}
                </div>
                <div className='col-sm-6 col-md-4 col-lg-3'>
                    <FormInput
                        label='Status'
                        name='Status'
                        labelClassName="required"
                        type='select'
                        value={status}
                        onChange={handleStatus}
                        errorText={statusError}
                    >
                        <Option value=''>Select</Option>
                        {STATUS.map(s => {
                            return <Option value={s.value}>{s.text}</Option>
                        })}
                    </FormInput>
                </div>

                <div className='col-sm-6 col-md-4 col-lg-3 text-center' style={{ marginTop: "30px" }}>
                    <button className="btn btn-secondary" onClick={handleClear} style={{ marginRight: "5px" }}>Clear</button>
                    <button className="btn btn-primary" onClick={handleSave}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default UserForm;
