import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AddStudent from "./pages/AddStudent";
import Branch from "./pages/Branch";
import Course from "./pages/Course";
import Dashboard from "./pages/Dashboard";
import { DropDownMaster } from "./pages/DropDownMaster";
import EditStudent from "./pages/EditStudent";
import FeesMaster from "./pages/FeesMaster";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import MainStudentView from "./pages/MainStudentView";
import NotFoundPage from "./pages/NotFoundPage";
import QualificationMaster from "./pages/QualificationMaster";
import RoleManagement from "./pages/RoleManagement";
import StudentAttachment from "./pages/StudentAttachment";
import StudentFees from "./pages/StudentFees";
import User from "./pages/User";
import { AuthUserType } from "./redux/actions/authUserActions";
import { fetchBranch } from "./redux/actions/branchActions";
import { fetchDropDown } from './redux/actions/dropDownAction';
import { fetchUser } from "./redux/actions/userActions";
import { StoreState } from "./redux/reducers";
import { AppDispatch } from "./redux/store";

function App() {

    const dispatch = useDispatch<AppDispatch>()
    const authUser = useSelector<StoreState, AuthUserType>((state: any) => state.authUser)

    // const authendicated = true
    const authenticated = typeof authUser.token === 'string' && authUser.token !== undefined && authUser.token !== ''

    useEffect(() => {
        if (authenticated) {
            dispatch(fetchUser())
            dispatch(fetchBranch())
            dispatch(fetchDropDown())
        }
    }, [authenticated])

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={authenticated ? <Dashboard /> : <Navigate to='/login' />} />
                <Route path="/branch" element={authenticated ? <Branch /> : <Navigate to='/' />} />
                <Route path="/login" element={!authenticated ? <Login /> : <Navigate to='/' />} />
                <Route path="/logout" element={authenticated ? <Logout /> : <Navigate to='/' />} />
                <Route path="/course" element={authenticated ? <Course /> : <Navigate to='/' />} />
                <Route path="/fees-master" element={authenticated ? <FeesMaster /> : <Navigate to='/' />} />
                <Route path="/qualification-master" element={authenticated ? <QualificationMaster /> : <Navigate to='/' />} />
                <Route path="/student" element={authenticated ? <MainStudentView /> : <Navigate to='/' />} />
                <Route path="/student-info" element={authenticated ? <AddStudent /> : <Navigate to='/' />} />
                <Route path="/student-fees/:id" element={authenticated ? <StudentFees /> : <Navigate to='/' />} />
                <Route path="/student-info/:id" element={authenticated ? <EditStudent /> : <Navigate to='/' />} />
                <Route path="/student-attachment/:id" element={authenticated ? <StudentAttachment /> : <Navigate to='/' />} />
                <Route path="/user" element={authenticated ? <User /> : <Navigate to='/' />} />
                <Route path="/role-permission" element={authenticated ? <RoleManagement /> : <Navigate to='/' />} />
                <Route path="/dropdown-master" element={authenticated ? <DropDownMaster /> : <Navigate to='/' />} />
                <Route path="*" element={authenticated ? <NotFoundPage /> : <Navigate to='/' />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
