import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { useSelector } from 'react-redux';
import Select, { SingleValue } from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from '../components/custom/Card';
import CollapseCard from '../components/custom/CollapseCard';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { LocalFormatDate } from '../config/functions';
import { DropDownType } from '../redux/actions/dropDownAction';
import { StoreState } from '../redux/reducers';
import "../styles/Table.css";
import { BranchType, CourseType, FeesMasterType } from './Interface';

type OptionType = { value: string; label: string };

const FeesMaster = () => {
    const branchList = useSelector<StoreState, BranchType[]>(state => state.branch)
    const [courseList, setCourseList] = useState<CourseType[]>([]);
    const [feesList, setFeesList] = useState<FeesMasterType[]>([]);
    const dropDownList = useSelector<StoreState, DropDownType[]>(state => state.dropDown)
    const [editFlag, setEditFlag] = useState(false);
    const [id, setId] = useState<number>(0);
    const [courseId, setCourseId] = useState<number>(0);
    const [courseName, setCourseName] = useState<string | undefined>('');
    const [feesType, setfeesType] = useState<string>('year');
    const [academicPeriod, setAcademicPeriod] = useState<string>('year');
    const [academicYear, setAcademicYear] = useState<string>('')
    const [years, setYears] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [lastDate, setLastDate] = useState<string>('');
    const [noOfSemester, setNoOfSemester] = useState<string>('');
    const [gridFields, setGridFields] = useState<{ title: string, amount: string, lastDate: string }[]>([]);
    const [branchId, setBranchId] = useState<string>('');

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const [courseIdError, setCourseIdError] = useState<string>('');
    const [feesTypeError, setfeesTypeError] = useState<string>('');
    const [amountError, setAmountError] = useState<string>('');
    const [yearsError, setYearsError] = useState<string>('');
    const [academicYearError, setAcademicYearError] = useState<string>('')
    const [noOfSemesterError, setNoOfSemesterError] = useState<string>('');

    const [showModal, setShowModal] = useState(false);

    //For filters
    const [branchIdFilter, setBranchIdFilter] = useState<string>('');
    const [courseIdFilter, setCourseIdFilter] = useState<number>(0);
    const [academicYearFilter, setAcademicYearFilter] = useState<string>("");
    const [feesTypeFilter, setfeesTypeFilter] = useState<string>('year');
    const [yearsFilter, setYearsFilter] = useState<string>('');
    const [noOfSemesterFilter, setNoOfSemesterFilter] = useState<string>('');

    //select option for fees title
    const optionsForTitle = dropDownList
        .filter((dl) => dl.category === 'Fees Title')
        .map((dd) => ({ value: dd.title, label: dd.title }));

    //for academic year
    let currentDate = new Date();
    let year = currentDate.getFullYear();

    let min = 0;
    let max = 10;


    let last10Years = [];
    for (let i = min; i <= max; i++) {
        let dropYear = year - i;
        last10Years.push(dropYear);
    }

    useEffect(() => {
        axios.get<{ data: CourseType[] }>(`${apiUrl}/course/`)
            .then((response) => {
                setCourseList(response.data.data);
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        fetchData();
    }, [courseIdFilter, academicYearFilter, feesTypeFilter, yearsFilter, noOfSemesterFilter]);

    const fetchData = () => {
        const academicPeriod = feesTypeFilter === 'year'
            ? (yearsFilter ? `'${yearsFilter}'` : null)
            : (noOfSemesterFilter ? `'${noOfSemesterFilter}'` : null);


        const course = courseIdFilter !== 0 ? courseIdFilter : null;

        const academicYearByFilter = academicYearFilter !== "" ? `'${academicYearFilter}'` : null;

        axios.get<{ data: FeesMasterType[] }>(`${apiUrl}/feesMaster/?courseId=${course}&academicYear=${academicYearByFilter}&feesType=${feesTypeFilter}&academicPeriod=${academicPeriod}`)
            .then((response) => {
                setFeesList(response.data.data);
            })
            .catch((error) => console.log(error));
    };

    const clearHandler = () => {
        setCourseId(0);
        setfeesType('year');
        setYears('');
        setNoOfSemester('');
        setAcademicYear('');
        setGridFields([]);
        setCourseIdError('');
        setfeesTypeError('');
        setYearsError('');
        setNoOfSemesterError('');
        setAcademicYearError('');
        setEditFlag(false);
    };

    const addHandler = () => {
        let error = false;

        if (courseId === 0) {
            setCourseIdError('Course ID is required');
            error = true;
        }

        if (academicYear === "") {
            setAcademicYearError('Academic year is required');
            error = true;
        }

        if (feesType === 'year' && years === '') {
            setYearsError('No of Years is required');
            error = true;
        } else if (feesType === 'semester' && noOfSemester === '') {
            setNoOfSemesterError('No of Semesters is required');
            error = true;
        }

        const nonEmptyGridFields = gridFields.filter((field) => field.title && field.amount && field.lastDate);

        if (nonEmptyGridFields.length === 0) {
            toast.error("At least one Title and Amount must be filled", { position: 'top-right' });
            error = true;
        }

        const academicPeriod = feesType === 'year' ? `${years}` : `${noOfSemester}`;

        const data = { courseId, academicYear, feesType, academicPeriod, fees: nonEmptyGridFields };

        if (!error) {
            axios.post(`${apiUrl}/feesMaster/`, data)
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        fetchData();
                        clearHandler();
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    };


    const editHandler = (id: number, courseName: string | undefined, academicYear: string, feesType: string, academicPeriod: string, title: string, amount: number, lastDate: string) => {
        setId(id);
        setAmount(amount.toString());
        setCourseName(courseName);
        setAcademicYear(academicYear);
        setTitle(title);
        setfeesType(feesType);
        setLastDate(lastDate)
        setAcademicPeriod(academicPeriod);
        setShowModal(true);
    };


    const updateHandler = () => {
        let error = false;

        if (amount === "") {
            setAmountError('Amount is required');
            error = true;
        }
        if (!error) {
            axios.put(`${apiUrl}/feesMaster/?id=${id}`, { amount })
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        fetchData();
                        setShowModal(false);
                    }
                })
                .catch((error) => toast.error(error.response.data.message));
        }
    };



    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number) => {
        setId(id);
        openDeleteConfirmation();
    };

    const confirmDeleteHandler = () => {
        axios.delete(`${apiUrl}/feesMaster/?id=${id}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                fetchData();
                closeDeleteConfirmation();
            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    const handleGridChange = (index: number, field: 'title' | 'amount' | 'lastDate', value: string) => {
        const updatedGridFields = [...gridFields];
        updatedGridFields[index] = { ...updatedGridFields[index], [field]: value };
        setGridFields(updatedGridFields);
    };

    return (
        <Main>
            <PageContainer title="Fees Master">
                <Card title={editFlag ? "Edit Fees" : "Add Fees"}>
                    <div className='card'>
                        <div className="row m-3">
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Branch <span className="text-danger">*</span> : </label>
                                <select className="form-control form-select" value={branchId} onChange={(e) => setBranchId(e.target.value)}>
                                    <option value="all">Select Branch</option>
                                    {branchList.map((branch) => (
                                        <option key={branch.id} value={branch.id}>{branch.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Course <span className="text-danger">*</span> : </label>
                                <select className="form-control form-select" value={courseId} onChange={(e) => setCourseId(Number(e.target.value))}>
                                    <option value={0}>Select Course</option>
                                    {courseList?.filter(f => branchId ? f.branchId.toString() === branchId.toString() : f)?.map((course) => (
                                        <option key={course.id} value={course.id}>{course.name}</option>
                                    ))}
                                </select>
                                {courseIdError && <div className="text-danger">{courseIdError}</div>}
                            </div>

                            {/* Academic Year */}
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Academic Year <span className='text-danger'>*</span>:</label>
                                <select name="" id="" className='form-control' value={academicYear} onChange={(e) => {
                                    setAcademicYear(e.target.value)
                                    setAcademicYearError('');
                                }}>
                                    <option value="">Select Academic Year</option>
                                    {
                                        last10Years.map((ly => {
                                            return <option value={ly}>{ly}</option>
                                        }))
                                    }
                                </select>
                                {academicYearError && <div className="text-danger">{academicYearError}</div>}
                            </div>

                            {/* Fee Type Radio Buttons */}
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Fees Type <span className="text-danger">*</span>:</label><br />
                                <input
                                    type="radio"
                                    name="year"
                                    value="year"
                                    checked={feesType === 'year'}
                                    onChange={(e) => setfeesType(e.target.value)}
                                /> Year
                                <input
                                    type="radio"
                                    name="semester"
                                    value="semester"
                                    className="ml-2"
                                    checked={feesType === 'semester'}
                                    onChange={(e) => setfeesType(e.target.value)}
                                /> Semester
                            </div>

                            {/* Conditionally render based on Fee Type */}
                            {feesType === 'year' ? (
                                <div className='col-sm-6 col-md-4 col-lg-2'>
                                    <label className="mt-3 mb-3"> Year <span className="text-danger">*</span> : </label>
                                    <select
                                        className="form-control form-select"
                                        value={years}
                                        onChange={(e) => setYears(e.target.value)}
                                    >
                                        <option value="">Select Year</option>
                                        {[1, 2, 3, 4, 5].map((yearOption) => (
                                            <option key={yearOption} value={yearOption}>{yearOption}</option>
                                        ))}
                                    </select>
                                    {yearsError && <div className="text-danger">{yearsError}</div>}
                                </div>
                            ) : (
                                <div className='col-sm-6 col-md-4 col-lg-3'>
                                    <label className="mt-3 mb-3">Semester <span className="text-danger">*</span> : </label>
                                    <select
                                        className="form-control form-select"
                                        value={noOfSemester}
                                        onChange={(e) => setNoOfSemester(e.target.value)}
                                    >
                                        <option value="">Select Semesters</option>
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((semesterOption) => (
                                            <option key={semesterOption} value={semesterOption}>{semesterOption}</option>
                                        ))}
                                    </select>
                                    {noOfSemesterError && <div className="text-danger">{noOfSemesterError}</div>}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Grid for Title and Amount */}
                    <div className="row m-3">
                        <table className="table table-striped w-50">
                            <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                <tr>
                                    <th>Title</th>
                                    <th>Amount</th>
                                    <th>Last Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {[...Array(15)].map((_, index) => (
                                    <tr key={index}>
                                        <td>
                                            <Select
                                                className="form-control p-0"
                                                value={optionsForTitle.find((option) => option.value === gridFields[index]?.title) || null}
                                                onChange={(selectedOption: SingleValue<OptionType>) => {
                                                    if (selectedOption) {
                                                        handleGridChange(index, 'title', selectedOption.value);
                                                    }
                                                }}
                                                options={optionsForTitle}
                                                placeholder="Select Fees Title"
                                                isSearchable
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={gridFields[index]?.amount || ''}
                                                onChange={(e) => handleGridChange(index, 'amount', e.target.value)}
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={gridFields[index]?.lastDate || ''}
                                                onChange={(e) => handleGridChange(index, 'lastDate', e.target.value)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div className="m-4 d-flex justify-content-end">
                        <button className="btn btn-sm btn-secondary" onClick={clearHandler} style={{ marginRight: '10px' }}>Clear</button>
                        <button className="btn btn-sm" onClick={addHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Add</button>
                    </div>
                </Card>
                <CollapseCard title="Fees List">
                    <div className="container-fluid" style={{ width: "100%" }}>
                        <div className="row mb-4">
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Branch  : </label>
                                <select className="form-control form-select" value={branchIdFilter} onChange={(e) => setBranchIdFilter(e.target.value)}>
                                    <option value="all">Select Branch</option>
                                    {branchList.map((branch) => (
                                        <option key={branch.id} value={branch.id}>{branch.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Course : </label>
                                <select className="form-control form-select" value={courseIdFilter} onChange={(e) => setCourseIdFilter(Number(e.target.value))}>
                                    <option value={0}>Select Course</option>
                                    {courseList?.filter(f => branchIdFilter ? f.branchId.toString() === branchIdFilter.toString() : f)?.map((course) => (
                                        <option key={course.id} value={course.id}>{course.name}</option>
                                    ))}
                                </select>
                            </div>

                            {/* Academic Year Filter */}
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Academic Year :</label>
                                <select name="" id="" className='form-control' value={academicYearFilter} onChange={(e) => {
                                    setAcademicYearFilter(e.target.value)
                                }}>
                                    <option value="">Select Academic Year</option>
                                    {
                                        last10Years.map((ly => {
                                            return <option value={ly}>{ly}</option>
                                        }))
                                    }
                                </select>
                            </div>

                            {/* Fee Type Radio Buttons */}
                            <div className='col-sm-6 col-md-4 col-lg-2'>
                                <label className="mt-3 mb-3">Fees Type :</label><br />
                                <input type="radio" name="feesType" value="year" checked={feesTypeFilter === 'year'} onChange={() => setfeesTypeFilter('year')} /> Year
                                <input type="radio" name="feesType" value="semester" className="ml-2" checked={feesTypeFilter === 'semester'} onChange={() => setfeesTypeFilter('semester')} /> Semester
                            </div>

                            {/* Conditionally render based on Fee Type */}
                            {feesTypeFilter === 'year' ? (
                                <div className='col-sm-6 col-md-4 col-lg-2'>
                                    <label className="mt-3 mb-3"> Year : </label>
                                    <select className="form-control form-select" value={yearsFilter} onChange={(e) => setYearsFilter(e.target.value)}>
                                        <option value="">Select Year</option>
                                        {["I year", "II year", "III year", "IV year", "V year"].map((yearOption) => (
                                            <option key={yearOption} value={yearOption}>{yearOption}</option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <div className='col-sm-6 col-md-4 col-lg-2'>
                                    <label className="mt-3 mb-3">No Of Semester : </label>
                                    <select className="form-control form-select" value={noOfSemesterFilter} onChange={(e) => setNoOfSemesterFilter(e.target.value)}>
                                        <option value="">Select No of Semesters</option>
                                        {[1, 2, 3, 4, 5, 6, 7, 8].map((semesterOption) => (
                                            <option key={semesterOption} value={semesterOption}>{semesterOption}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </div>
                        <table className="table table-striped">
                            <thead style={{ backgroundColor: "#10296C", color: "#fff" }}>
                                <tr>
                                    <th>S.No</th>
                                    <th>Course</th>
                                    <th>Academic Year</th>
                                    <th>FeesType</th>
                                    <th>Academic Period</th>
                                    <th>Title</th>
                                    <th>Last Date</th>
                                    <th>Amount</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                {feesList.map((fees, index) => (
                                    <tr key={fees.id}>
                                        <td>{index + 1}</td>
                                        <td>{fees.courseName}</td>
                                        <td>{fees.academicYear}</td>
                                        <td>{fees.feesType}</td>
                                        <td>{fees.academicPeriod}</td>
                                        <td>{fees.title}</td>
                                        <td>{LocalFormatDate(fees.lastDate)}</td>
                                        <td>{fees.amount}</td>
                                        <td>
                                            <button className="btn btn-sm btn-primary" style={{ marginRight: '10px', backgroundColor: '#10296C' }} onClick={() => editHandler(fees.id, fees.courseName, fees.academicYear, fees.feesType, fees.academicPeriod, fees.title, fees.amount, fees.lastDate)}>Edit</button>
                                            <button className="btn btn-sm btn-danger" onClick={() => deleteHandler(fees.id)}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </CollapseCard>
                {/* Update Modal */}
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Fees</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Course Name:</label>
                            <input type="text" className="form-control" value={courseName} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Academic Year:</label>
                            <input type="text" className="form-control" value={academicYear} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Fees Type:</label>
                            <input type="text" className="form-control" value={feesType} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Academic Period:</label>
                            <input type="text" className="form-control" value={academicPeriod} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Title:</label>
                            <input type="text" className="form-control" value={title} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Last Date:</label>
                            <input type="text" className="form-control" value={lastDate} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Amount <span className="text-danger">*</span> :</label>
                            <input
                                type="number"
                                className="form-control"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            {amountError && <div className="text-danger">{amountError}</div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className='btn btn-sm' onClick={() => setShowModal(false)}>Close</Button>
                        <Button variant="primary" className='btn btn-sm' onClick={updateHandler} style={{ backgroundColor: "#10296C", color: "#fff" }}>Update</Button>
                    </Modal.Footer>
                </Modal>
                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this fee record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>Cancel</Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            </PageContainer>
            <ToastContainer />
        </Main>
    );
};

export default FeesMaster;
