import React, { Suspense } from "react";
import Footer from './Footer';
import SideNavBar from './SideNavBar';
import TopNavBar from './TopNavBar';

interface MainProps {
    children: React.ReactNode;
}

const Main: React.FC<MainProps> = ({ children }) => {
    return (
        <>
            <Suspense>
                <SideNavBar />
            </Suspense>
            <Suspense>
                <TopNavBar />
            </Suspense>
            <section className="content">
                {children}
            </section>
            <Suspense>
                <Footer />
            </Suspense>

        </>
    );
}

export default Main;
