import React, { FC } from "react";

interface Props {
    title:string,
    footer?:string ,
    children: string | JSX.Element | JSX.Element[]
}
const CollapseCard:FC<Props> = (props) =>{
    const {title,footer,children} = props;
    return(
        <div className="card">
        <div className="card-header">
          <h3 className="card-title" style={{color: "#10296C"}}>{title}</h3>

          <div className="card-tools">
            <button type="button" className="btn btn-tool" data-card-widget="collapse" title="Collapse">
              <i className="fas fa-minus"></i>
            </button>
            <button type="button" className="btn btn-tool" data-card-widget="remove" title="Remove">
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
        <div className="card-body" style={{padding:"10px"}}>
          <div style={{overflowX:"scroll"}}>
         {
            children
         }
         </div>
        </div>
        {
         footer && 
        <div className="card-footer">
          {
            footer
          }
        </div>
        }
      </div>
    )
}
export default CollapseCard;