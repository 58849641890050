import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from "jspdf-autotable";
import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Feather from 'react-feather';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import Card from '../components/custom/Card';
import Main from '../components/custom/Main';
import PageContainer from '../components/custom/PageContainer';
import { apiUrl } from '../config';
import { LocalFormatDate } from '../config/functions';
import '../styles/StudentFees.css';
import { StudentFeesDetails, StudentFeesPayment, StudentFeesType } from './Interface';
import StudentPayment from './StudentPayment';

const StudentFees = () => {
    const { id } = useParams();
    const [studentFeesList, setStudentFeesList] = useState<StudentFeesType[]>([]);
    const [studentFeesDetails, setStudentFeesDetails] = useState<StudentFeesDetails[]>([]);
    const [loading, setLoading] = useState(false);

    console.log(id);

    const [title, setTitle] = useState('');
    // const [feesType, setFeesType] = useState('');
    const [academicPeriod, setAcademicPeriod] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ids, setId] = useState<number>(0);
    const [billNo, setBillNo] = useState<number>(0);
    const [feesType, setfeesType] = useState<string>('year');
    const [academicYear, setAcademicYear] = useState<string>('');
    const [amount, setAmount] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [remarks, setRemarks] = useState<string>('');
    const [amountError, setAmountError] = useState<string>('');
    const [paymentDetails, setPaymentDetails] = useState<'fees' | 'concession'>('fees');

    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const fetchStudentFeesList = (id: string) => {
        axios.get<{ data: StudentFeesType[] }>(`${apiUrl}/student/studentFees/?id=${id}`)
            .then((response) => {
                setStudentFeesList(response.data.data);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        if (!id) return;
        fetchStudentFeesList(id)
    }, [id]);

    const fetchFeesDetails = async (id: number, title: string, feesType: string, academicPeriod: string) => {
        const endpoint = paymentDetails === "fees" ? "studentFee" : "studentConcession"
        if (id && title) {
            try {
                const response = await axios.get<{ data: StudentFeesDetails[] }>(`${apiUrl}/${endpoint}/?id=${id}&title=${title}&feesType=${feesType}&academicPeriod=${academicPeriod}`);
                const data = response.data.data;
                setStudentFeesDetails(data);
                setTitle(title);
                setAcademicPeriod(academicPeriod)
                setfeesType(feesType)
            } catch (error) {
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (id) {
            fetchFeesDetails(Number(id), title, feesType, academicPeriod);
        }
    }, [id, title, paymentDetails, feesType, academicPeriod]);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => {
        setIsModalOpen(false)
        fetchStudentFeesList(id ? id : '0')
    };


    const downloadPDF = () => {
        const doc = new jsPDF();
        const title = "Student Fees Report";

        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleWidth = doc.getTextWidth(title);
        const titleX = (pageWidth - titleWidth) / 2;
        doc.text(title, titleX, 10);

        const student = studentFeesList[0];

        autoTable(doc, {
            startY: 20,
            theme: 'plain',
            styles: { fontSize: 10, lineColor: [0, 0, 0], lineWidth: 0.3 },
            head: [['Student Info', 'Details']],
            body: [
                ['Name', student.studentName],
                ['Register Number', student.registerNumber],
                ['Branch', student.branchName],
                ['Course', student.courseName],
                ['Batch', student.batch],
                ['Phone No', student.phoneNumber],
            ]
        });

        const totalAmount = studentFeesList.reduce((sum, stu) => sum + (stu.amount || 0), 0);
        const totalAmountPaid = studentFeesList.reduce((sum, stu) => sum + (stu.amountPaid || 0), 0);
        const totalConcession = studentFeesList.reduce((sum, stu) => sum + (stu.scAmount || 0), 0);
        const totalBalance = studentFeesList.reduce(
            (sum, stu) => sum + (stu.amount - ((stu.amountPaid || 0) + (stu.scAmount || 0))),
            0
        );

        autoTable(doc, {
            startY: 80,
            theme: 'plain',
            styles: { fontSize: 9, lineColor: [0, 0, 0], lineWidth: 0.3 },
            headStyles: { fontSize: 8 },
            head: [['S.No', 'Academic Year', 'Term', 'Fees Title', 'Amount', 'Due Date', 'Amount Paid', 'Concession', 'Balance']],
            body: studentFeesList.map((stu, i) => [
                i + 1,
                stu.academicYear,
                stu.academicPeriod + ' ' + stu.feesType,
                stu.title,
                stu.amount,
                LocalFormatDate(stu.lastDate),
                stu.amountPaid ?? 0,
                stu.scAmount ?? 0,
                // stu.remarks ?? "-",
                stu.amount - ((stu.amountPaid ?? 0) + (stu.scAmount ?? 0))
            ]),
            foot: [[
                { content: 'Total', colSpan: 4, styles: { halign: 'right', fontStyle: 'bold' } }, // Merge first 4 columns for the label
                totalAmount, // Total Amount
                '', // Empty for Due Date
                totalAmountPaid, // Total Amount Paid
                totalConcession, // Total Concession
                totalBalance, // Total Balance
            ]],

        });

        // Save the PDF
        doc.save('student_fees.pdf');
    };

    const generatePDF = (studentFeeList: StudentFeesPayment[], feesTitle: string, amount: number) => {
        const doc = new jsPDF();
        const imgPath = studentFeeList[0]?.photo
        const student = studentFeeList.length > 0 ? studentFeeList[0] : null;

        const title = student?.branchName || "Unknown Branch";

        doc.setFontSize(11);
        const pageWidth = doc.internal.pageSize.getWidth();

        const titleX = (pageWidth) / 2;

        doc.text(title, titleX + 5, 12, { align: 'center', maxWidth: 80 });
        doc.addImage(imgPath, 'JPEG', titleX - 70, 3, 30, 30);


        doc.setFontSize(10);

        let yPos = 20;

        const approvedText = student?.approvedBy ? `(${student?.approvedBy})` : "";
        if (approvedText) {
            const textWidth1 = doc.getTextWidth(approvedText);
            const textX1 = (pageWidth - textWidth1) / 2;
            doc.text(approvedText, textX1 + 7, yPos);
            yPos += 5;
        }

        const recognisedText = student?.recognisedBy ? `(${student?.recognisedBy})` : "";
        if (recognisedText) {
            const textWidth2 = doc.getTextWidth(recognisedText);
            const textX2 = (pageWidth - textWidth2) / 2;
            doc.text(recognisedText, textX2 + 7, yPos);
            yPos += 5;
        }

        const sponsoredText = student?.sponsoredBy ? `(${student?.sponsoredBy})` : "";
        if (sponsoredText) {
            const textWidth3 = doc.getTextWidth(sponsoredText);
            const textX3 = (pageWidth - textWidth3) / 2;
            doc.text(sponsoredText, textX3 + 7, yPos);
            yPos += 5;
        }

        const universityText = student?.affiliatedUniversity ? `(${student?.affiliatedUniversity})` : "";
        if (universityText) {
            const textWidth4 = doc.getTextWidth(universityText);
            const textX4 = (pageWidth - textWidth4) / 2;
            doc.text(universityText, textX4 + 7, yPos);
            yPos += 8;
        }

        const placeText = student?.place ? `${student?.place}` : "";
        if (placeText) {
            const textWidth5 = doc.getTextWidth(placeText);
            const textX5 = (pageWidth - textWidth5) / 2;
            doc.text(placeText, textX5 + 7, yPos);
            yPos += 8;
        }

        doc.line(10, yPos - 2, 90, yPos - 2);
        doc.line(120, yPos - 2, 200, yPos - 2);

        doc.setFontSize(14);
        const textWidth6 = doc.getTextWidth("Fees Receipt");
        const textX6 = (pageWidth - textWidth6) / 2;
        doc.text("Fees Receipt", textX6, yPos);
        yPos += 10;

        const headerRows1: any[] = [
            [
                { content: "Bill No: " + (student?.billNo), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Date: " + (LocalFormatDate(student?.date || "default date")), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Name: " + (student?.studentName), colSpan: 4, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
            [
                { content: "Class: " + (student?.courseName), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
                { content: "Regn.No: " + (student?.registerNumber), colSpan: 2, styles: { halign: "left", fontStyle: 'bold', fontSize: 8 } },
            ],
        ];

        autoTable(doc, {
            startY: yPos,
            theme: 'plain',
            styles: { fontSize: 8, lineColor: [0, 0, 0], lineWidth: 0.3 },
            head: [],
            body: headerRows1,
        });

        const particularsData = [[`${feesTitle}`, `${amount}`]];

        // Calculate the total amount
        const totalAmount = amount;

        // Define the footer row
        const footerRows3: any[] = [
            [
                { content: 'Total', styles: { halign: "right" } },
                { content: `Rs. ${totalAmount}`, styles: { halign: "left" } },
            ]
        ];


        autoTable(doc, {
            startY: (doc as any).lastAutoTable.finalY + 10,
            theme: 'plain',
            styles: { fontSize: 10 },
            head: [['Particulars', 'Rs.']],
            body: particularsData,
            foot: footerRows3,


            headStyles: {
                lineWidth: 0.3,
                lineColor: [0, 0, 0],
                cellWidth: 'auto',
                halign: 'center',
                valign: 'middle',
                fontSize: 11,
                fontStyle: 'bold',
                cellPadding: 5,
            },

            bodyStyles: {
                fontSize: 10,
                lineWidth: 0.3,
                lineColor: [0, 0, 0],
                fontStyle: 'normal',
                cellPadding: 5,
                halign: 'left',
                valign: 'middle',
            },

            footStyles: {
                lineWidth: 0.05,
                lineColor: [0, 0, 0],
                fontSize: 10,
                fontStyle: 'bold',
                cellPadding: 2,
                halign: 'right',
                valign: 'middle',
            },

            didDrawCell: (data) => {
                if (data.row.index >= 0) {
                    if (data.column.index === 0) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x + data.cell.width, data.cell.y, data.cell.x + data.cell.width, data.cell.y + data.cell.height);
                    }
                    if (data.column.index === 1) {
                        doc.setLineWidth(0.3);
                        doc.setDrawColor(0, 0, 0);
                        doc.line(data.cell.x, data.cell.y, data.cell.x, data.cell.y + data.cell.height);
                    }
                }
            },
        });

        // doc.text(`Total: Rs. ${totalAmount}`, 10, (doc as any).lastAutoTable.finalY + 10);

        doc.line(10, (doc as any).lastAutoTable.finalY + 12, 200, (doc as any).lastAutoTable.finalY + 12);


        doc.setFontSize(10);
        doc.setFont("times", "italic");
        doc.text("Fees once paid will not be refunded under any circumstances.", 10, (doc as any).lastAutoTable.finalY + 25);
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        doc.text("Signature of Receiving Officer", 150, (doc as any).lastAutoTable.finalY + 30);

        doc.save('student_fees_receipt.pdf');
    };

    const editHandler = (id: number, billNo: number, academicYear: string, feesType: string, title: string, amount: number, date: string, remarks: string) => {
        setId(id);
        setBillNo(billNo);
        setAmount(amount.toString());
        setAcademicYear(academicYear);
        setTitle(title);
        setfeesType(feesType);
        setDate(date);
        setRemarks(remarks);
        setShowModal(true);
    };

    const updateHandler = () => {
        let error = false;

        const endpoint = paymentDetails === "fees" ? "studentFee" : "studentConcession"

        if (amount === "") {
            setAmountError('Amount is required');
            error = true;
        }

        setLoading(true);
        if (!error) {
            axios.put(`${apiUrl}/${endpoint}/?id=${ids}`, { amount, remarks })
                .then((response) => {
                    if (response.data.error) {
                        toast.error(response.data.message, { position: 'top-right' });
                    } else {
                        toast.success(response.data.message, { position: 'top-right' });
                        setShowModal(false);
                        fetchStudentFeesList(id ? id : '0')

                        if (paymentDetails === "fees") {
                            axios.get<{ data: StudentFeesPayment[] }>(`${apiUrl}/studentFee/paymentPDF/?id=${id}&billNo=${billNo}`)
                                .then((response) => {
                                    if (response.data && response.data.data) {
                                        const studentFeeList: StudentFeesPayment[] = response.data.data;

                                        setTimeout(() => {
                                            if (studentFeeList.length > 0) {
                                                generatePDF(studentFeeList, title, parseInt(amount));
                                            }
                                        }, 1000);
                                    }
                                });
                        }

                        fetchFeesDetails(ids, title, feesType, academicPeriod)

                    }
                })
                .catch((error) => toast.error(error.response.data.message))
                .finally(() => setLoading(false));
        }
    };

    const openDeleteConfirmation = () => {
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setShowDeleteConfirmation(false);
    };

    const deleteHandler = (id: number, title: string) => {
        setId(id);
        setTitle(title)
        openDeleteConfirmation();
    };

    const confirmDeleteHandler = () => {
        const endpoint = paymentDetails === "fees" ? "studentFee" : "studentConcession"

        axios.delete(`${apiUrl}/${endpoint}/?id=${ids}`)
            .then((response) => {
                toast.success(response.data.message, { position: 'top-right' });
                closeDeleteConfirmation();
                fetchFeesDetails(ids, title, feesType, academicPeriod)
                fetchStudentFeesList(id ? id : '0')

            })
            .catch((error) => {
                toast.error(error.response.data.message, { position: 'top-right' });
            });
    };

    return (
        <Main>
            <PageContainer title="Student Fees">

                <div className='card m-2'>
                    <div className='row p-2'>
                        <div className='col-sm-12 col-md-12 col-lg-4'>
                            <img
                                src={apiUrl + "/" + studentFeesList[0]?.photoUrl}
                                alt={studentFeesList[0]?.studentName}
                                className="student-thumbnail"
                                style={{ objectFit: "contain", height: "100px", width: "100px" }}
                            />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-5' style={{ marginLeft: "0px" }}>
                            <h6>Name: <span style={{ fontWeight: "normal" }}>{studentFeesList[0]?.studentName}</span></h6>
                            <h6>Register No: <span style={{ fontWeight: "normal" }}>{studentFeesList[0]?.registerNumber}</span></h6>
                            <h6>Branch: <span style={{ fontWeight: "normal" }}>{studentFeesList[0]?.branchName}</span></h6>
                            <h6>Course: <span style={{ fontWeight: "normal" }}>{studentFeesList[0]?.courseName}</span></h6>
                            <h6>Batch: <span style={{ fontWeight: "normal" }}>{studentFeesList[0]?.batch}</span></h6>
                        </div>
                    </div>
                </div>
                <div className="student-fees-container">
                    <div className="fees-list-section">
                        <Card title="STUDENT FEES LIST">
                            <table className="table table-hover text-center">
                                <thead style={{ backgroundColor: "#10296C", color: "#ffffff" }}>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Academic Year</th>
                                        <th>Term</th>
                                        <th>Fees Title</th>
                                        <th>Amount</th>
                                        <th>Due Date</th>
                                        <th>Amount Paid</th>
                                        <th>Concession</th>
                                        {/* <th>Remarks</th> */}
                                        <th>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {studentFeesList.map((student, index) => (
                                        <tr
                                            key={index + 1}
                                            className="table-row cursor-pointer"
                                            onClick={() => fetchFeesDetails(student.studentId, student.title, student.feesType, student.academicPeriod)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <td>{index + 1}</td>
                                            <td>{student.academicYear}</td>
                                            <td>{student.academicPeriod} {student.feesType}</td>
                                            <td>{student.title}</td>
                                            <td>{student.amount}</td>
                                            <td>{LocalFormatDate(student.lastDate)}</td>
                                            <td>{student.amountPaid ?? 0}</td>
                                            <td>{student.scAmount ?? 0}</td>
                                            {/* <td>{student.remarks ?? "-"}</td> */}
                                            <td>{student.amount - ((student.amountPaid ?? 0) + (student.scAmount ?? 0))}</td>
                                        </tr>
                                    ))}
                                    <tr style={{ fontWeight: "bold", backgroundColor: "#f0f0f0" }}>
                                        <td colSpan={4} style={{ textAlign: "right", paddingRight: "60px" }}>Total:</td>
                                        <td>
                                            {studentFeesList.reduce((sum, student) => sum + student.amount, 0)}
                                        </td>
                                        <td></td>
                                        <td>
                                            {studentFeesList.reduce((sum, student) => sum + (student.amountPaid ?? 0), 0)}
                                        </td>
                                        <td>
                                            {studentFeesList.reduce((sum, student) => sum + (student.scAmount ?? 0), 0)}
                                        </td>
                                        <td>
                                            {studentFeesList.reduce((sum, student) => sum + (student.amount - ((student.amountPaid ?? 0) + (student.scAmount ?? 0))), 0)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn btn-success btn-sm m-2"
                                    onClick={downloadPDF}
                                    style={{ width: "7%", height: "5%", fontSize: "16px" }}
                                >
                                    <Feather.Download className='m-0' style={{ fontSize: "2px" }} /> PDF
                                </button>
                                <Button className="btn btn-sm m-2" variant="primary" onClick={openModal} style={{ width: "7%", backgroundColor: '#10296C' }}>Payment</Button>
                            </div>

                        </Card>
                    </div>
                    <div className="fees-details-section">
                        <div className="col-12 mb-3">
                            <label style={{ marginRight: "1%" }}>Payment Type:</label>
                            <input
                                type="radio"
                                name="paymentDetails"
                                value="fees"
                                checked={paymentDetails === "fees"}
                                onChange={() => setPaymentDetails("fees")}
                            /> Fees
                            <input
                                type="radio"
                                name="paymentDetails"
                                value="concession"
                                className="ml-2"
                                checked={paymentDetails === "concession"}
                                onChange={() => setPaymentDetails("concession")}
                            /> Concession
                        </div>
                        <div className="fees-details-card">
                            <Card title={`${title} DETAILS`}>
                                <table className="table text-center">
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            {paymentDetails === "concession" ? '' : <th>Bill.No</th>}
                                            <th>Academic Year</th>
                                            <th>Term</th>
                                            <th>Fees Title</th>
                                            <th>{paymentDetails === "concession" ? "Concession" : "Amount"}</th>
                                            {paymentDetails === "concession" ? <th>Remarks</th> : ""}
                                            <th>Date</th>
                                            <th>Options</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentFeesDetails.map((details, index) => {
                                            const todayDate = new Date().toISOString().split('T')[0];
                                            return (
                                                <tr key={index + 1}>
                                                    <td>{index + 1}</td>
                                                    {paymentDetails === "concession" ? '' : <td>{details.billNo}</td>}
                                                    <td>{details.academicYear}</td>
                                                    <td>{details.academicPeriod}{details.feesType}</td>
                                                    <td>{details.title}</td>
                                                    <td>{details.amount}</td>
                                                    {paymentDetails === "concession" ? <td>{details.remarks}</td> : ""}
                                                    <td>{LocalFormatDate(details.date)}</td>
                                                    <td>
                                                        {details.date === todayDate && (
                                                            <>
                                                                <button
                                                                    className="btn btn-sm btn-primary"
                                                                    style={{ marginRight: '10px', backgroundColor: '#10296C' }}
                                                                    onClick={() => editHandler(details.id, details.billNo || 0, details.academicYear, details.feesType, details.title, details.amount, details.date, details.remarks || "")}
                                                                >
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() => deleteHandler(details.id, details.title)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>

                                </table>
                            </Card>
                        </div>
                    </div>
                </div>

                {/* Modal for Payment */}
                <Modal show={isModalOpen} onHide={closeModal} centered size="lg" className="modal-lg-custom">
                    <Modal.Header closeButton>
                        <Modal.Title>Payment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <StudentPayment id={id ?? 0} onclose={closeModal} /> {/* Render the StudentPayment component inside the modal */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeModal}>Close</Button>
                    </Modal.Footer>
                </Modal>

                {/* Update Modal */}
                <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Fees</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label>Academic Year:</label>
                            <input type="text" className="form-control" value={academicYear} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Fees Type:</label>
                            <input type="text" className="form-control" value={feesType} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Title:</label>
                            <input type="text" className="form-control" value={title} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Last Date:</label>
                            <input type="text" className="form-control" value={LocalFormatDate(date)} readOnly />
                        </div>
                        <div className="form-group">
                            <label>Amount <span className="text-danger">*</span> :</label>
                            <input
                                type="number"
                                className="form-control"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            {amountError && <div className="text-danger">{amountError}</div>}
                        </div>
                        {paymentDetails === 'concession' ? <div className="form-group">
                            <label>Remarks <span className="text-danger">*</span> :</label>
                            <input
                                type="text"
                                className="form-control"
                                value={remarks}
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </div> : ""}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className='btn btn-sm' onClick={() => setShowModal(false)}>Close</Button>
                        <Button variant="primary" className='btn btn-sm' onClick={updateHandler} disabled={loading} style={{ backgroundColor: "#10296C", color: "#fff" }}> {loading ? <Spinner /> : "Update"}</Button>
                    </Modal.Footer>
                </Modal>

                {/* Deletion Confirmation Modal */}
                <Modal show={showDeleteConfirmation} onHide={closeDeleteConfirmation} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this fee record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeDeleteConfirmation}>Cancel</Button>
                        <Button variant="danger" onClick={confirmDeleteHandler}>Delete</Button>
                    </Modal.Footer>
                </Modal>

            </PageContainer>
            <ToastContainer />
        </Main>
    );
};

export default StudentFees;
