import React, { FC, useState } from "react";
import * as Feather from 'react-feather';
import { Link } from 'react-router-dom';

export interface MenuItemTypes {
    key: string;
    label: string;
    isTitle?: boolean;
    Icon?: Feather.Icon;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemTypes[];
}

export const MENU_ITEMS: MenuItemTypes[] = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        isTitle: false,
        Icon: Feather.Monitor,
        url: '/',
    },
    {
        key: 'branch',
        label: 'Branch',
        isTitle: false,
        Icon: Feather.BarChart,
        url: '/branch',
    },
    {
        key: 'course',
        label: 'Course',
        isTitle: false,
        Icon: Feather.BookOpen,
        url: '/course',
    },
    {
        key: 'feesmaster',
        label: 'Fees Master',
        isTitle: false,
        Icon: Feather.DollarSign,
        url: '/fees-master',
    },
    {
        key: 'student',
        label: 'Student',
        isTitle: false,
        Icon: Feather.Users,
        url: '/student',
    },
    {
        key: 'user',
        label: 'User',
        isTitle: false,
        Icon: Feather.User,
        url: '/user',
    },
    {
        key: 'dropdownMaster',
        label: 'DropDown Master',
        isTitle: false,
        Icon: Feather.ChevronDown,
        url: '/dropdown-master',
    },
];

const SideNavBar: FC = () => {
    const renderMenuItem = (menuItem: MenuItemTypes) => {
        const { Icon } = menuItem;
        return (
            <li className="nav-item" key={menuItem.key}>
                <Link to={menuItem.url || ''} className="nav-link">
                    {Icon && <Icon style={{ marginRight: "5px" }} />}
                    <p>{menuItem.label}</p>
                </Link>
            </li>
        );
    };

    return (
        <aside className="main-sidebar elevation-4" style={{ backgroundColor: "#10296C", position: "fixed" }}>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="https://i.pinimg.com/550x/90/c8/d0/90c8d0fde36b92c18a6c19a06b4b2735.jpg" className="img-circle elevation-2" alt="User" />
                    </div>
                    <div className="info">
                        <Link to="#" className="d-block">College Management</Link>
                    </div>
                </div>
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" data-accordion="false">
                        {MENU_ITEMS?.map((menuItem) =>
                            menuItem.children ? (
                                <MenuItem key={menuItem.key} menuItem={menuItem} />
                            ) : (
                                renderMenuItem(menuItem)
                            )
                        )}
                        <li className="nav-item">
                            <Link to="/logout" className="nav-link">
                                <Feather.LogOut style={{ marginRight: "5px" }} />
                                <p>Logout</p>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

const MenuItem: FC<{ menuItem: MenuItemTypes }> = ({ menuItem }) => {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const { Icon } = menuItem;

    const handleToggleMenuClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowSubMenu(!showSubMenu);
    };

    return (
        <li className="nav-item" key={menuItem.key}>
            <Link to="#" className="nav-link" onClick={handleToggleMenuClick} aria-expanded={showSubMenu}>
                {Icon && <Icon style={{ marginRight: "5px" }} />}
                <p>
                    {menuItem.label}
                    <i className={`right fas ${!showSubMenu ? "fa-angle-left" : "fa-angle-down"}`}></i>
                </p>
            </Link>
            <ul className="nav nav-treeview" style={{ display: showSubMenu ? 'block' : 'none' }}>
                {menuItem.children?.map((childItem) => (
                    <li className="nav-item" key={childItem.key}>
                        <Link to={childItem.url || '#'} className="nav-link">
                            <i className="fas fa-angle-right nav-icon"></i>
                            <p>{childItem.label}</p>
                        </Link>
                    </li>
                ))}
            </ul>
        </li>
    );
};

export default SideNavBar;
